const button = {
  height: 'var(--button-size)',
  '&.-square': { width: 'var(--button-size)' },
  '&:not(.-square)': { padding: '0 calc(var(--button-size) * 0.35)' },
  '&.-full': { width: '100%' },

  '&[disabled]': {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  gap: '1.5em',

  borderRadius: '200px',
  whiteSpace: 'nowrap',

  backgroundColor: 'var(--bg)',
  color: 'var(--fg)',
  borderWidth: 'var(--stlr-stroke)',
  borderStyle: 'solid',
  borderColor: 'var(--br)',

  '--transition': '0.2s ease',
  transition: 'color var(--transition), background-color var(--transition), border-color var(--transition)',

  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: 'var(--bg-hover)',
      color: 'var(--fg-hover)',
      borderColor: 'var(--br-hover)',
    },
  },

  '&.-primary': {
    '--fg': 'var(--stlr-color-light)',
    '--bg': 'var(--stlr-color-primary)',
    '--br': 'var(--stlr-color-primary)',

    '--fg-hover': 'var(--fg)',
    '--bg-hover': 'var(--stlr-color-primary-alt)',
    '--br-hover': 'var(--stlr-color-primary-alt)',
  },
  '&.-secondary': {
    '--fg': 'var(--stlr-color-text)',
    '--bg': 'var(--stlr-color-secondary)',
    '--br': 'var(--stlr-color-secondary)',

    '--fg-hover': 'var(--fg)',
    '--bg-hover': 'var(--stlr-color-secondary-alt)',
    '--br-hover': 'var(--stlr-color-secondary-alt)',
  },
  '&.-tertiary': {
    '--fg': 'var(--stlr-color-text)',
    '--bg': 'var(--stlr-color-light)',
    '--br': 'var(--stlr-color-light)',

    '--fg-hover': 'var(--fg)',
    '--bg-hover': 'var(--stlr-color-light-alt)',
    '--br-hover': 'var(--stlr-color-light-alt)',
  },
  '&.-outlined': {
    '--fg': 'var(--stlr-color-text)',
    '--bg': 'var(--stlr-color-background)',
    '--br': 'var(--stlr-color-text)',

    '--fg-hover': 'var(--fg)',
    '--bg-hover': 'var(--stlr-color-light)',
    '--br-hover': 'var(--stlr-color-text)',
  },

  '&.-with-icon .stlr-button-icon': {
    width: '1.4em',
  },
  '&:not(.-with-icon) .stlr-button-icon': {
    width: '1.2em',
  },

  '.stlr-button-icon': {
    display: 'flex',
  },
  '.stlr-button-text': { lineHeight: 2 },
}

export default button
