const container = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,

  alignItems: 'center',
  justifyContent: 'center',
  padding: 'var(--stlr-padding) 0',
  '@media screen and (min-width: 62em)': {
    padding: 'var(--stlr-padding) 0 calc(var(--button-size) + (var(--stlr-padding) * 3)) 0',
  },

  '>section': {
    backgroundColor: 'var(--stlr-color-background)',
    color: 'var(--stlr-color-text)',
    padding: 'var(--stlr-padding)',

    borderRadius: 'var(--stlr-padding)',

    overflow: 'hidden auto',
    WebkitOverflowScrolling: 'touch',
    backfaceVisibility: 'hidden',
    transform: 'translateZ(0px)',

    width: '100%',
    height: '100%',

    maxWidth: 'calc(100% - (var(--stlr-padding) * 2))',

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"content" "image"',
    gap: 'var(--stlr-padding)',
    minHeight: 0,

    '@media screen and (min-width: 62em)': {
      gridTemplateColumns: 'auto auto',
      gridTemplateAreas: '"image content"',

      height: 'clamp(100px, 100%, 60em)',
      width: 'fit-content',
    },
  },

  '.stlr-image': {
    gridArea: 'image',

    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',

    '&[aria-hidden=true]': { display: 'none' },

    '@media screen and (min-width: 62em)': {
      aspectRatio: '2/3',
      width: 'fit-content',
    },

    'img,video': {
      borderRadius: 'var(--stlr-padding)',
      border: 'var(--stlr-stroke) solid currentColor',
      overflow: 'hidden',

      width: '100%',
      height: '100%',

      '@media screen and (min-width: 62em)': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
      },

      objectFit: 'cover',
    },
  },

  '.stlr-content': {
    gridArea: 'content',

    '@media screen and (min-width: 62em)': {
      aspectRatio: '7/8',
      overflow: 'hidden auto',
      WebkitOverflowScrolling: 'touch',
      backfaceVisibility: 'hidden',
      transform: 'translateZ(0px)',
    },

    width: '100%',
    height: '100%',
    borderRadius: 'var(--stlr-padding)',
    backgroundColor: 'var(--stlr-color-light)',
    padding: 'var(--stlr-padding)',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 'var(--stlr-gap)',

    '>div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '7vmin',
    },

    '.stlr-caption': {
      '>h4': {
        borderBottom: 'var(--stlr-stroke) solid currentColor',
        paddingBottom: '0.5em',
        marginBottom: '0.35em',
      },
      '>h5': {
        textAlign: 'right',
        '.stlr-t-text': { marginLeft: '0.25em' },
      },
      marginBottom: '1em',
    },
  },
}

export default container
