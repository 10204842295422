const wrapper = {
  '--button-size': '3em',

  zIndex: 'var(--stlr-z-index, 100)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  maxHeight: '100%',

  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',

  width: '100%',

  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  fontFamily: 'var(--stlr-font-family)',
  lineHeight: 'var(--stlr-font-line-height)',

  'h1,h2,h3,h4,h5,h6,p': {
    fontFamily: 'var(--stlr-font-family)!important',
  },

  '.grecaptcha-badge': { visibility: 'visible!important' },

  '.stlr-t-title': { fontSize: 'var(--stlr-size-title)' },
  '.stlr-t-subtitle': { fontSize: 'var(--stlr-size-subtitle)' },
  '.stlr-t-caption': {
    fontSize: 'var(--stlr-size-caption)',
    textTransform: 'uppercase',
  },
  '.stlr-t-text': { fontSize: 'var(--stlr-size-text)' },

  '&[aria-hidden=true]': {
    display: 'none',
    pointerEvents: 'none',
  },

  '[data-svg="absolute"]': {
    'svg *': {
      strokeWidth: 'var(--stlr-stroke)',
      vectorEffect: 'non-scaling-stroke',
    },
  },
  '[data-svg-self="absolute"]': {
    '*': {
      strokeWidth: 'var(--stlr-stroke)',
      vectorEffect: 'non-scaling-stroke',
    },
  },
}

export default wrapper
