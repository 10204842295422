import { useWallet, useConnectedWallet, WalletStatus } from '@terra-money/wallet-provider'

const useConnection = () => {
  const { status, connect, disconnect } = useWallet()
  const connectedWallet = useConnectedWallet()

  const ready = !!(status !== 'INITIALIZING')
  const connected = !!(status === WalletStatus.WALLET_CONNECTED)
  const address = connected ? connectedWallet.walletAddress : null

  return {
    ready,
    connected,
    address,
    preview: crop(address ?? ''),
    action: {
      connect,
      disconnect,
    },
  }
}

function crop(string = '', range = 8) {
  const [start, end] = [
    string?.substr(0, range),
    string?.substr(string?.length - range, string?.length - 1),
    //
  ]

  return start + '...' + end
}

export default useConnection
