import Button from './Button'
import { Wallet } from '../svg'

const Connect = ({ children, connection, modal, ...props }) => {
  const { action, connected, preview } = connection ?? {}

  const connectWallet = (value = '') => {
    action.connect(value)
    setTimeout(() => modal.action.close(), 500)
  }

  const handleClick = connected
    ? () => action.disconnect()
    : () => {
        modal.action.push({
          title: 'Connect your wallet',
          children: (
            <>
              <Button className="-outlined" onClick={() => connectWallet('EXTENSION')}>
                Connect with Extension
              </Button>
              <Button className="-outlined" onClick={() => connectWallet('WALLETCONNECT')}>
                Connect with Mobile
              </Button>
            </>
          ),
        })
      }

  return (
    <Button className="-tertiary" icon={<Wallet />} data-connected={connected} onClick={handleClick} {...props}>
      {connected ? preview : children}
    </Button>
  )
}

export default Connect
