import * as React from 'react'

function SvgWallet(props) {
  return (
    <svg viewBox="0 0 72 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69.5 16H10.163c-2.032 0-3.982.755-5.419 2.1C3.307 19.445 2.5 21.27 2.5 23.17v0c0 1.902.807 3.726 2.244 5.07 1.437 1.345 3.387 2.1 5.419 2.1h51.034c1.09 0 2.17.202 3.178.592a8.363 8.363 0 012.693 1.684 7.756 7.756 0 011.8 2.52c.417.943.632 1.953.632 2.974V66.23c0 1.02-.215 2.03-.632 2.973a7.756 7.756 0 01-1.8 2.52 8.363 8.363 0 01-2.693 1.685 8.799 8.799 0 01-3.178.591H10.803c-2.202 0-4.314-.819-5.871-2.275C3.375 70.268 2.5 68.292 2.5 66.23V24.013"
        stroke="var(--a, currentColor)"
        strokeWidth={3.5}
        strokeMiterlimit={10}
      />
      <path
        d="M50.354 59.5c2.643 0 4.786-2.164 4.786-4.833 0-2.67-2.143-4.834-4.786-4.834s-4.786 2.165-4.786 4.834c0 2.67 2.143 4.833 4.786 4.833z"
        fill="var(--a, currentColor)"
      />
    </svg>
  )
}

export default SvgWallet
