const theme = {
  color: {
    light: '#ffffff',
    text: '#000000',
    background: '#F3F2EF',

    primary: '#389FFE',
    secondary: '#CEFF00',
    success: '#229B15',
    error: '#FF3100',

    lightAlt: '#eaeaea',
    primaryAlt: '#5fb2fe',
    secondaryAlt: '#e1ff65',
    successAlt: '#42e331',
    errorAlt: '#fe6e4c',
  },
  size: {
    title: 'clamp(14px, 4vmin, 50px)',
    subtitle: 'clamp(14px, 7.29px + 1.79vw, 52px)',
    caption: 'clamp(11px, 10.12px + 0.24vw, 16px)',
    text: 'clamp(11px, calc(10.12px + 0.24vw), 16px)',
  },
  font: {
    family: 'helvetica, arial, sans-serif',
    lineHeight: '1.25',
  },

  padding: '16px',
  stroke: '1px',
  gap: '30px',

  zIndex: '100',
}

export default theme
