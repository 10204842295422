import { useRef } from 'react'

const BASE = {
  playsInline: true,
  'webkit-playsinline': 'true',
  preload: 'auto',
  muted: true,
  autoPlay: true,
  loop: true,
}

const Asset = ({ image = '', video = '', ...props }) => {
  let videoRef = useRef(null)

  const handlePlayVideo = () => {
    const playing = isVideoPlaying(videoRef.current)
    if (!!playing) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
  }

  return (
    <div onClick={!!video ? handlePlayVideo : null} {...props}>
      {!!video ? (
        <video ref={videoRef} src={video} poster={image} {...BASE} />
      ) : (
        !!image && <img src={image} alt="alt" />
      )}
    </div>
  )
}

function isVideoPlaying(node) {
  return !!(node.currentTime > 0 && !node.paused && !node.ended && node.readyState > 2)
}

export default Asset
