import Button from './Button'
import { Success, Error, Blank, External, Loader } from '../svg'

const Modal = ({ modal = {}, ...props }) => {
  const { title = '', type = '', message = [], refs = [], children = null } = modal?.data[1] ?? {}

  const cta = (
    <Button onClick={modal.action.close} className="-outlined -full">
      Close
    </Button>
  )

  const content = {
    success: { icon: <Success />, buttons: cta },
    error: { icon: <Error />, buttons: cta },
    pending: { icon: <Blank />, buttons: null },
  }[type] || { icon: null, buttons: null }

  const view = (
    <div className="stlr-modal-container" data-column data-with-refs={!!refs?.length || false}>
      {!!content.icon && (
        <div className="stlr-modal-icon">
          {type === 'pending' && <Loader className="stlr-loader" data-svg-self="absolute" />}
          {content.icon}
        </div>
      )}

      {!!title && <h2 className="stlr-t-title">{title}</h2>}

      {!!message.length && (
        <div className="stlr-modal-text">
          {message.map((string, i) => (
            <p key={'modal-text' + i} className="stlr-t-text">
              {string}
            </p>
          ))}
        </div>
      )}

      {(children || content.buttons) && (
        <div className="stlr-modal-body" data-column>
          {children}
          {content.buttons}
        </div>
      )}

      {!!refs?.length && (
        <div className="stlr-modal-refs" data-column>
          {refs.map((obj, i) => (
            <RefItem key={'ref' + i} {...obj} />
          ))}
        </div>
      )}
    </div>
  )

  return modal.active ? (
    <div className="stlr-modal" data-type={type} {...props}>
      <div className="stlr-modal-wrapper" data-column>
        {view}
      </div>
    </div>
  ) : null
}

function RefItem({ name, value, url, sx, ...props }) {
  return (
    <div className="stlr-modal-ref" {...props}>
      <h5>{name}</h5>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {value}
          <span>
            <External />
          </span>
        </a>
      ) : (
        <span>{value}</span>
      )}
    </div>
  )
}

export default Modal
