import { LCDClient } from '@terra-money/terra.js'

const NETWORK = 'MAINNET'
export const TEST_ADDRESS = null

export const MAINNET = {
  name: 'mainnet',
  chainID: 'columbus-5',
  URL: 'https://lcd.terra.dev',
}
export const TESTNET = {
  URL: 'https://bombay-lcd.terra.dev',
  chainID: 'bombay-12',
}

export const NET = { MAINNET, TESTNET }[NETWORK]

export const getClient = () => new LCDClient(NET)

export const CONTRACT = {
  DOMAIN_FCD: 'https://fcd.terra.dev',
  TRANSACTION_URL: 'https://finder.terra.money/columbus-5/tx/',
  ADDRESS: 'terra1xe5n8aa8xvtqtwtaj6k9fu0kd209thyff3gfuk',
}

export const walletConnectChainIds = {
  0: TESTNET,
  1: MAINNET,
}
