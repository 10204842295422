import { css } from '@emotion/css'

import reset from './reset'
import wrapper from './wrapper'
import header from './header'
import container from './container'
import modal from './modal'
import button from './button'

const styles = css({
  ...reset,
  ...wrapper,

  header,
  button,
  '.stlr-container': container,
  '.stlr-modal': modal,
})

export default styles
