import merge from 'lodash.merge'
import kebabCase from 'lodash.kebabcase'

const toVarName = (key) => `--${kebabCase(key)}`
const join = (...args) => args.filter(Boolean).join('-')

export const objectToVars = (parent, obj) => {
  let vars = {}
  for (let key in obj) {
    const name = join(parent, key)
    const value = obj[key]
    if (value && typeof value === 'object') {
      vars = {
        ...vars,
        ...objectToVars(name, value),
      }
    } else {
      vars[toVarName(name)] = value
    }
  }
  return vars
}

export const makeTheme = (theme = {}, obj = {}, selector) => {
  const styles = merge(theme, obj)
  const to = objectToVars('stlr', styles)

  return (
    <style
      type="text/css"
      id="styllar-theme"
      dangerouslySetInnerHTML={{
        __html:
          `${selector}{` +
          Object.entries(to)
            .map(([key, value]) => `${key}:${value};`)
            .join('') +
          '}',
      }}
    />
  )
}
