import React from 'react'
import ReactDOM from 'react-dom'

import Widget from './Widget'
import Provider from './components/Provider'

let props = {}

const Stlr = {
  config: (config) => (props = config),
  init: (selector = '#stlr-root') => {
    ReactDOM.render(
      <Provider>
        <Widget selector={selector} {...props} />
      </Provider>,
      document.querySelector(selector)
    )
  },
}

// ReactDOM.render(
//   <React.StrictMode>
//     <div style={{ height: '600vh' }}>
//       <Provider>
//         <Widget
//           selector="#root"
//           contract="terra1c9tujyumd0xzs3f62tx5xxjwzzr0398acwjp55"
//           recaptcha_sitekey="6LcINHEeAAAAAFTb1FgwFZnXWzutPG6pnK72k7mF"
//         />
//       </Provider>
//     </div>
//   </React.StrictMode>,
//   document.getElementById('root')
// )

export default Stlr
