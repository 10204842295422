import { useState } from 'react'

const useModal = () => {
  const [data, setData] = useState([
    '',
    {
      type: '',
      title: '',
      message: [],
      children: null,
      refs: [],
    },
  ])

  return {
    data,
    active: { open: true, close: false }[data[0]] || false,
    action: {
      push: (args = {}) => setData(['open', args]),
      update: (args = {}) => setData((prev) => ['open', { ...prev[1], ...args }]),
      close: () => setData(['close']),
    },
  }
}

export default useModal
