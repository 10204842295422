const modal = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  gridColumn: '1/-1',
  gridRow: '1/-1',
  zIndex: '10',

  margin: 'calc(var(--stlr-padding) * -1)',
  backgroundColor: 'var(--stlr-color-background)',

  '@keyframes rotate': {
    from: { transform: 'rotate(0.01deg)' },
    to: { transform: 'rotate(360deg)' },
  },

  '&[data-type="success"]': { '--b': 'var(--stlr-color-success)' },
  '&[data-type="error"]': { '--b': 'var(--stlr-color-error)' },

  '&[data-type="pending"]': {
    '--a': 'var(--stlr-color-light)',
    '--b': 'var(--stlr-color-primary)',

    '.stlr-loader': {
      animation: 'rotate 0.75s infinite linear',
    },
  },

  '.stlr-loader': {
    position: 'absolute',
    top: 'var(--pos)',
    left: 'var(--pos)',

    '--size': '3em',
    '--stlr-stroke': '0.12em',
    '--pos': 'calc(50% - (var(--size) / 2))',

    height: 'var(--size)!important',
  },

  '.stlr-modal-wrapper': {
    justifyContent: 'center',
  },
  '.stlr-modal-container': {
    '--padding': '1.5em',

    flexGrow: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 'var(--padding)',
    gap: '2em',

    '&[data-with-refs=false]': { paddingBottom: 'var(--padding)' },
    '&[data-with-refs=true]': { paddingBottom: 0 },
  },
  '.stlr-modal-body': {
    width: '100%',
    gap: '1em',
  },
  '.stlr-modal-text': {
    width: '100%',
    paddingLeft: '3em',
    paddingRight: '3em',
    textAlign: 'center',
  },
  '.stlr-modal-title': {
    textAlign: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  '.stlr-modal-icon': {
    position: 'relative',
    svg: { width: 'unset', height: 'clamp(6em, 20vmin, 8em)' },
  },
  '.stlr-modal-refs': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1em',

    borderTopWidth: 'var(--stlr-stroke)',
    borderTopStyle: 'solid',
    borderTopColor: 'rgba(0,0,0,0.85)',
    paddingTop: 'var(--stlr-padding)',
  },
  '.stlr-modal-ref': {
    display: 'flex',
    justifyContent: 'space-between',

    '>a,>span': {
      opacity: 0.3,
      span: {
        display: 'inline-block',
        width: '0.6em',
        height: '0.6em',
        marginLeft: '0.4em',
      },
    },
  },
}

export default modal
