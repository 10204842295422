import * as React from 'react'

function SvgExternal(props) {
  return (
    <svg viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28 6L2 32" stroke="var(--a, #000)" strokeWidth={3.62} />
      <path d="M2 5h26.87v26.87" stroke="var(--a, #000)" strokeWidth={3.5} />
    </svg>
  )
}

export default SvgExternal
