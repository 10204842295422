import * as React from 'react'

function SvgCross(props) {
  return (
    <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#cross_svg__clip0_1503:5201)" stroke="var(--a, currentColor)" strokeWidth={3.623}>
        <path d="M34.01 2.51L1.988 34.532M34.01 34.533L1.988 2.511" />
      </g>
      <defs>
        <clipPath id="cross_svg__clip0_1503:5201">
          <path fill="#fff" d="M0 0h36v37H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgCross
