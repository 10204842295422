import * as React from 'react'

function SvgSuccess(props) {
  return (
    <svg viewBox="0 0 89 125" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#success_svg__clip0_1378:5151)">
        <path
          d="M86.316 64.638a3.852 3.852 0 00-2.68-1.397 3.75 3.75 0 00-.26-.006 98.788 98.788 0 001.585-11.41c1.271-16.34-2.338-29.487-10.437-38.022C65.708 4.515 55.894 0 44.521 0c-11.36 0-21.176 4.524-30.007 13.83C6.416 22.363 2.79 35.507 4.027 51.84c.297 3.826.817 7.63 1.557 11.395-.07 0-.141.001-.21.005a3.849 3.849 0 00-2.69 1.398C.294 67.341-.228 73.63.08 78.615v.003c.483 7.648 6.034 14.849 9.984 14.849.047 0 .091-.012.138-.014.3 1.201.644 2.4 1.05 3.591a45.916 45.916 0 0011.87 18.696l.347.338c3.177 3.097 7.132 6.951 11.872 8.119 2.985.644 6.04.914 9.091.803a36.802 36.802 0 009.089-.802c4.742-1.169 8.696-5.023 11.874-8.12l.348-.339A45.916 45.916 0 0077.61 97.044c.408-1.195.753-2.399 1.054-3.605.091.008.178.028.271.028 3.95 0 9.5-7.2 9.983-14.85v-.002c.309-4.985-.213-11.274-2.603-13.977z"
          fill="var(--b, #333)"
        />
      </g>
      <path
        d="M28 64.708l8.341 16.064c.661 1.272 2.48 1.279 3.15.011L60 42"
        stroke="var(--a, #000)"
        strokeWidth={6}
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="success_svg__clip0_1378:5151">
          <path fill="#fff" d="M0 0h89v125H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgSuccess
