const reset = {
  textSizeAdjust: '100%',
  WebkitTapHighlightColor: 'transparent',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  textRendering: 'optimizeLegibility',
  fontFeatureSettings: "'kern', 'liga', 'clig', 'calt'",
  '*,*::before,*::after': { boxSizing: 'border-box' },
  '*:focus': { outline: 'none', boxShadow: 'none' },
  '*::-moz-focus-inner': { border: 0 },
  'a,area,button,input,label,select,textarea,[tabindex]': { touchAction: 'manipulation' },
  'p,table,blockquote,address,pre,iframe,form,figure,dl': { margin: '0' },
  'h1,h2,h3,h4,h5,h6': {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    margin: '0',
  },
  a: { backgroundColor: 'transparent', textDecoration: 'none', color: 'inherit' },
  'button,input,optgroup,select,textarea': {
    WebkitAppearance: 'none',
    appearance: 'none',
    verticalAlign: 'middle',
    color: 'inherit',
    font: 'inherit',
    border: '0',
    background: 'transparent',
    padding: '0',
    margin: '0',
    outline: '0',
    borderRadius: '0',
    textAlign: 'inherit',
  },
  'button,input': { overflow: 'visible' },
  'button,select': { textTransform: 'none' },
  'button,[type="button"],[type="reset"],[type="submit"]': {
    cursor: 'pointer',
    WebkitAppearance: 'none',
    appearance: 'none',
  },
  'button[disabled],[type="button"][disabled],[type="reset"][disabled],[type="submit"][disabled]': {
    cursor: 'default',
  },
  'button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner':
    {
      borderStyle: 'none',
      padding: '0',
    },
  'button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  img: {
    width: '100%',
    height: 'auto',
    pointerEvents: 'none',
    '&:not([src])': {
      visibility: 'hidden',
    },
    borderStyle: 'none',
    verticalAlign: 'bottom',
  },
  svg: {
    display: 'flex',
    flexGrow: '1',
    pointerEvents: 'none',
    '&:not(:root)': {
      overflow: 'hidden',
    },
  },
  '[data-aspect-ratio]::after': {
    content: '""',
    paddingBottom: 'calc(100% / (var(--aspect-ratio)))',
    display: 'flex',
  },
  '[data-column]': {
    display: 'flex',
    flexDirection: 'column',
  },
}

export default reset
