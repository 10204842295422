import { MsgExecuteContract, Coins } from '@terra-money/terra.js'
import {
  CreateTxFailed,
  Timeout,
  TxFailed,
  TxUnspecifiedError,
  useConnectedWallet,
  UserDenied,
} from '@terra-money/wallet-provider'

import { CONTRACT, getClient } from '../__consts'

const useSubmission = ({ connection, modal }) => {
  const connectedWallet = useConnectedWallet()

  const hashObject = (hash = '', name = 'Transaction Hash') => ({
    name,
    value: connection.preview,
    url: CONTRACT.TRANSACTION_URL + hash,
  })

  const send = async ({
    msgs: custom_msgs = null,
    senderWallet = connectedWallet?.walletAddress ?? '',
    contractAddress = CONTRACT.ADDRESS,
    msgProps = [],
    denom = 'uluna',
    gasPrice,
    onSuccess = () => {},
  }) => {
    if (!connectedWallet) return null

    // prettier-ignore
    const _msgs = [new MsgExecuteContract(
      senderWallet,
      contractAddress,
      ...msgProps
    )]

    const msgs = custom_msgs ?? _msgs

    const terra = getClient()

    let fees = {
      gas: '2000000',
      gasAdjustment: 1.5,
    }

    if (denom === 'uluna') {
      delete fees['gas']
    }

    try {
      const account = await terra.auth.accountInfo(senderWallet)
      const sequenceNumber = account.getSequenceNumber()

      modal.action.push({
        type: 'pending',
        title: 'Validating Transaction',
        message: ['This transaction is in progress'],
      })

      const fee = await terra.tx
        .estimateFee([{ sequenceNumber }], {
          msgs,
          gasPrices: new Coins(`${gasPrice}${denom}`),
          feeDenoms: [denom],
          ...fees,
        })
        .catch((error) => {
          const message = error.response.data.message
            .replace('failed to execute message; message index: 0: ', '')
            .replace(': execute wasm contract failed: invalid request', '')

          modal.action.push({
            type: 'error',
            title: 'Create Tx Failed',
            message: [message],
          })

          throw error
        })

      modal.action.push({
        type: 'pending',
        title: 'Confirm transaction',
        message: ['Proceed with the transaction in your Terra Station'],
      })

      connectedWallet
        .post({ msgs, fee })
        .then((nextTxResult) => {
          modal.action.push({
            type: 'pending',
            title: 'Validating Transaction',
            message: ['This transaction is in progress'],
            refs: [hashObject(nextTxResult.result.txhash)],
          })
          if (nextTxResult.success) {
            verifyTransaction(nextTxResult, onSuccess)
          }
        })
        .catch((error) => {
          if (error instanceof UserDenied) {
            modal.action.push({ type: 'error', title: 'User Denied Transaction' })
          } else if (error instanceof CreateTxFailed) {
            modal.action.push({ type: 'error', title: 'Create Tx Failed', message: [error.message] })
          } else if (error instanceof TxFailed) {
            modal.action.push({ type: 'error', title: 'Tx Failed', message: [error.message] })
          } else if (error instanceof Timeout) {
            modal.action.push({ type: 'error', title: 'Timeout' })
          } else if (error instanceof TxUnspecifiedError) {
            modal.action.push({ type: 'error', title: 'Unspecified Error', message: [error.message] })
          } else {
            modal.action.push({
              type: 'error',
              title: 'Unknown Error',
              message: [error instanceof Error ? error.message : String(error)],
            })
          }
        })
    } catch (error) {
      const { message } = error.response.data
      if (message.includes('code = NotFound desc = account')) {
        console.log(error)
        modal.push({
          type: 'error',
          title: 'Wallet not found',
          message: [error.response.data.message],
        })
      }
    }
  }

  let txInfo = null

  const verifyTransaction = async (txResult, onSuccess) => {
    const terra = getClient()

    let timeout = setTimeout(async function run() {
      try {
        txInfo = await terra.tx.txInfo(txResult.result.txhash)
        if (txInfo.raw_log.includes('transfer_nft')) {
          modal.action.push({ type: 'success', title: 'Transaction was successful!' })
        } else {
          modal.action.push({
            type: 'error',
            title: 'Transaction Failed',
            message: [txInfo.raw_log],
            refs: [hashObject(txResult.result.txhash)],
          })
        }
      } catch (error) {
        console.log(error)
      }
      if (txInfo != null) {
        clearTimeout(timeout)
        onSuccess()
      } else {
        setTimeout(run, 1000)
      }
    }, 1000)
  }

  return {
    send,
    MsgExecuteContract,
    senderWallet: connectedWallet?.walletAddress ?? '',
    contractAddress: CONTRACT.ADDRESS,
  }
}

export const claimRequest = (contractAddress, send, onSuccess) => {
  fetch(`${CONTRACT.DOMAIN_FCD}/v1/txs/gas_prices`)
    .then((res) => res.json())
    .then((res) => {
      send({
        contractAddress,
        msgProps: [{ claim: {} }],
        gasPrice: res.uluna,
        denom: 'uluna',
        onSuccess,
      })
    })
    .catch((error) => console.error('Error:', error))
}

export default useSubmission
