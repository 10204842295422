import { useRef, useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { makeTheme } from './utils'
import theme from './theme'
import styles from './styles'

import useModal from './hooks/useModal'
import useConnection from './hooks/useConnection'
import useSubmission, { claimRequest } from './hooks/useSubmission'

import Modal from './components/Modal'
import Connect from './components/Connect'
import Button from './components/Button'
import Asset from './components/Asset'
import Specs from './components/Specs'

import { Logo, Cross } from './svg'
import { NET } from './__consts'

const App = ({ theme: overrides = {}, contract = '', recaptcha_sitekey = '', selector }) => {
  const [active, setActive] = useState(false)
  const [data, setData] = useState({})

  let recaptchaRef = useRef(null)

  const modal = useModal()
  const connection = useConnection()
  const { send } = useSubmission({ modal, connection })

  const claim = () => claimRequest(contract, send, () => getData(contract, setData))
  const handleReCaptcha = () => (!!recaptcha_sitekey ? recaptchaRef.current.execute() : claim())

  useEffect(() => {
    getData(contract, setData)

    document.querySelectorAll('[data-stlr],.-stlr').forEach((node) => {
      node.addEventListener('click', () => setActive(true))
    })
  }, []) // eslint-disable-line

  const daily_locked = (data?.total_today ?? 0) === (data?.max_per_day ?? 1)

  return (
    <>
      {makeTheme(theme, overrides, selector)}

      <div className={styles} aria-hidden={!!!active}>
        <header>
          <a href="https://styllar.com" target="_blank" rel="noreferrer noopener">
            <Logo />
          </a>
          <nav>
            <Connect connection={connection} modal={modal}>
              Connect Wallet
            </Connect>
            <Button
              className="-primary -square"
              icon={<Cross />}
              onClick={() => {
                setActive(false)
                modal.action.close()
              }}
            />
          </nav>
        </header>

        <div className="stlr-container">
          <section className="stlr-container-wrapper">
            <Modal modal={modal} />

            <Asset image={data?.nft_image_url} video="" className="stlr-image" aria-hidden={!!modal.active} />
            <Specs
              contract={contract}
              name={data?.name}
              daily={[data?.total_today, data?.max_per_day]}
              qty={[data?.total_sold, data?.total_minted]}
            >
              <Button
                className="-secondary -full"
                onClick={handleReCaptcha}
                disabled={daily_locked || !!!connection.connected}
              >
                {daily_locked ? 'Try again tomorrow' : 'Claim Airdrop'}
              </Button>
            </Specs>
          </section>

          {!!recaptcha_sitekey && (
            <ReCAPTCHA ref={recaptchaRef} sitekey={recaptcha_sitekey} onChange={claim} size="invisible" />
          )}
        </div>
      </div>
    </>
  )
}

function getData(contract, setData) {
  fetch(`${NET.URL}/wasm/contracts/${contract}/store?query_msg={"query_total_status":{}}`)
    .then((response) => response.json())
    .then((data) => setData(data.result))
    .catch((error) => console.error('Error:', error))
}

export default App
