import * as React from 'react'

function SvgLoader(props) {
  return (
    <svg viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M69 45c0 13.255-10.745 24-24 24S21 58.255 21 45s10.745-24 24-24"
        stroke="var(--a, #000)"
        strokeWidth={4}
      />
    </svg>
  )
}

export default SvgLoader
