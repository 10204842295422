const header = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  padding: 'var(--stlr-padding)',
  width: '100%',

  a: {
    display: 'flex',
    height: '2.25em',
    color: 'var(--stlr-color-light)',
  },
  nav: {
    display: 'flex',
    gap: '1rem',
  },
}

export default header
