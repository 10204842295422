const Specs = ({ children, name = '', daily, qty, ...props }) => {
  return (
    <aside className="stlr-content" {...props}>
      {!!name && (
        <>
          <div>
            <h3 className="stlr-t-title">{name}</h3>
            <div>
              <Caption caption="Claimable Daily" amount={daily} />
              <Caption caption="Full Quantity" amount={qty} />
            </div>
          </div>
          {children}
        </>
      )}
    </aside>
  )
}

function Caption({ caption, amount = [0, 0], ...props }) {
  return (
    <div className="stlr-caption">
      <h4 className="stlr-t-caption">{caption || ''}</h4>
      <h5>
        <span className="stlr-t-title">{amount[0]}</span>
        <span className="stlr-t-text">/{amount[1]}</span>
      </h5>
    </div>
  )
}

export default Specs
