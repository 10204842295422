import { WalletProvider } from '@terra-money/wallet-provider'
import { NET, walletConnectChainIds } from '../__consts'

const Provider = ({ children, ...props }) => (
  <WalletProvider defaultNetwork={NET} walletConnectChainIds={walletConnectChainIds} {...props}>
    {children}
  </WalletProvider>
)

export default Provider
