const Button = ({ children, className = '', icon, ...props }) => {
  const withIcon = !!(children && icon) ? '-with-icon' : ''

  return (
    <button className={`stlr-t-caption ${withIcon} ${className}`} data-svg="absolute" {...props}>
      {children && <span className="stlr-button-text">{children}</span>}
      {icon && <div className="stlr-button-icon">{icon}</div>}
    </button>
  )
}

export default Button
